import React from "react";
import { FooterBottomData } from "@/data";
import { StaticQuery, graphql } from "gatsby";
import imageUrlBuilder from '@sanity/image-url'
import clientConfig from '../../client-config'
import {TwitterTimelineEmbed} from 'react-twitter-embed'

const Footer = () => {
  const builder = imageUrlBuilder(clientConfig.sanity)
  function urlFor(source) {
    return builder.image(source)
  }

  return (
    <StaticQuery
      query={graphql`
      query footerNav {
        foot:sanityFooter {
            about
            mainImage {
              asset {
                url
              }
            }
            imageTag
            links {
              title
              linkUrl
              link {
                slug {
                  current
                }
              }
            }
          }
          office:allSanityOffice(
            sort: { fields: [order], order: ASC }
            ) {
            nodes {
              phone
              order
              state
              street
              title
              zipcode
              city
            }
          }
        }
      `}
      render={data => (
        <footer className="footer_area">
          <div className="footer_contact text-center">
            <div className="container">
              <div className="row items-stretch">
                {data.office.nodes.map(({ title, street, city, state, zipcode, phone }, index) => (
                  <div className="col-md-4 col-sm-4 footer_contact_width text-left p-4" key={`footer-info-${index}`}>
                      <p className="ml-6">{title} <i className="icon-placeholder"></i></p>
                      <p className="ml-6"><span>{street}</span></p>
                      <p className="ml-6"><span>{city}, {state} {zipcode}</span></p>
                      <p className="ml-6"><a style={{fontWeight:"normal", color:"#a9a7ad"}} href={"tel:"+phone}>{phone}</a></p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="footer_content section_padding">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-8 col-sm-6">
                  <div className="footer_textwidget textwidget">
                    <h2>Ferrara Fiorenza PC</h2>
                    <p>{data.foot.about}</p>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-3">
                  <h2>Quick Links</h2>
                  <ul className="footer_link">
                    {data.foot.links.map((label , index) => (
                      <li key={`footer-link-one-${index}`}>
                        <a href={label.linkUrl !== null ? label.linkUrl : "/"+label.link.slug.current}>{label.title}</a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12 mb-4">
                <h2>Find us on Twitter</h2>
                <div style={{border:"1px solid", borderColor:"#535356", height: "300px", overflowX: "hidden", overflowY:"scroll"}} className="rounded p-2">
                  <TwitterTimelineEmbed
                    onLoad={function noRefCheck(){}}
                    options={{
                      height: 300
                    }}
                    noHeader
                    transparent
                    noBorders
                    theme="dark"
                    screenName="ferrarafiorenza"
                    sourceType="profile"
                    className="w-full"
                  />
                </div>
              </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="footer_textwidget textwidget flex gap-4 items-center">
                    <img src={urlFor(data.foot.mainImage).width(150).height(150).url()} alt={data.foot.imageTag} className="img-fluid rounded-lg"/>
                    <p className="mb-0">{data.foot.imageTag}</p>
                  </div>
                </div>
              </div>
              
              <div className="copyright_area">
                <div className="row">
                  <div className="col-md-8 col-sm-6 copyright_text">
                    <p>
                      &copy; {new Date().getFullYear()} Ferrara Fiorenza PC
                    </p>
                  </div>
                  <div className="col-md-4 col-sm-6 copyright_social text-end">
                    <ul>
                      {FooterBottomData.social.map(({ icon, url }, index) => (
                        <li key={`footer-social-link-${index}`}>
                          <a href={url}>
                            <i className={`fa ${icon}`}></i>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="footer_shape">
            <Img src={FooterShape} alt="footer shape" />
          </div> */}
        </footer>
    )}
    />
  );
};

export default Footer;
