import React, { useContext, Fragment, useRef } from "react";
import Link from "@/components/link";
import { NavLinksData } from "@/data";
import { MenuContext } from "@/context/menu-context";
import { StaticQuery, graphql } from "gatsby";


const MobileMenu = () => {
  const { menuStatus, updateMenuStatus } = useContext(MenuContext);
  const menuEl = useRef(null);
  const handleMenuClick = (e) => {
    e.preventDefault();
    updateMenuStatus(!menuStatus);
  };
  return (
    <div className="mobile-menu animated fadeInLeft">
      <div className="mobile-menu__overlay" onClick={handleMenuClick}></div>
      <div className="mobile-menu__inner">
        <div className="mobile-menu__top">
          {/* <h1 className="mobile-menu__logo">
            <Link href="/">Loazzne</Link>
          </h1> */}
          <a href="#" className="mobile-menu__close" onClick={handleMenuClick}>
            <i className="fa fa-times"></i>
          </a>
        </div>
        <nav className="mobile-menu__links" ref={menuEl}>
        <StaticQuery
            query={graphql`
              query mobileNavQuery {
                nav:sanityNavigation {
                  title
                  sections {
                    title
                    links {
                      linkUrl
                      title
                      external
                      link {
                        slug {
                          current
                        }
                      }
                    }
                    linkUrl
                    target {
                      title
                      slug {
                        current
                      }
                    }
                  }
                }
              }  
            `}
            render={data => (
              <ul>
                {data.nav.sections.map((navItem, index) => {
                  return (
                    <li
                      key={index}
                      className={`${
                        navItem.links.length > 0 ? "menu-item-has-children" : ""
                      }`}
                    >
                      <a href={navItem.linkUrl !== null ? navItem.linkUrl : "/"+navItem.target.slug.current}>{navItem.title}</a>
                      {navItem.links.length > 0  ? (
                        <Fragment>
                          <button
                            onClick={(e) => {
                              menuEl.current
                                .querySelectorAll(".sub-menu")
                                .forEach((item) => {
                                  item.classList.remove("show");
                                });

                              let clickedItem = e.currentTarget.parentNode;
                              clickedItem
                                .querySelector(".sub-menu")
                                .classList.toggle("show");
                            }}
                          >
                            <i className="fa fa-angle-down"></i>
                          </button>
                          <ul className="sub-menu">
                            {navItem.links.map((subLinks, index) => (
                              <li key={index}>
                                <a href={subLinks.linkUrl !== null ? subLinks.linkUrl : "/"+subLinks.link.slug.current}>{subLinks.title}</a>
                              </li>
                            ))}
                          </ul>
                        </Fragment>
                      ) : null}
                    </li>
                  );
                })}
              </ul>
            )}
          />
          
        </nav>
        <div className="mobile-menu__text">
        Ferrara Fiorenza PC offers a blend of expertise and thoughtful representation in analyzing and solving the challenges faced by school districts, BOCES and all types of employers.
        </div>
        <div className="mobile-menu__socials text-xs text-zinc-200">
            Copyright 2022 Ferrara Fiorenza PC
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
