import { Link } from "gatsby"
import { default as React } from "react"
import {
  connectStateResults,
  Highlight,
  Hits,
  Index,
  Snippet,
  PoweredBy,
} from "react-instantsearch-dom"
const HitCount = connectStateResults(({ searchResults, indexTitle }) => {
  const hitCount = searchResults && searchResults.nbHits
  return hitCount > 0 ? (
    <div className="HitCount">
      {hitCount} result{hitCount !== 1 ? `s` : ``} in {indexTitle}
    </div>
  ) : null
})
const PageHit = ({ hit }) => (
  <div>
    <a href={hit?._type === "post" && "/articles/"+hit.current || hit._type === "event" && "/events/"+hit.current || hit._type === "page" && "/"+hit.current }>
      <h6>
        <Highlight attribute="title" hit={hit} tagName="mark" />
      </h6>
    </a>
    <Snippet attribute="excerpt" hit={hit} tagName="mark" />
  </div>
)
const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <HitCount indexTitle={index.title} /> 
    <Hits className="Hits" hitComponent={PageHit} />
  </Index>
)
const SearchResult = ({ indices, className }) => (
  <div className={className}>
    {indices.map(index => (
      <HitsInIndex index={index} key={index.name} />
    ))}
    <PoweredBy />
  </div>
)
export default SearchResult