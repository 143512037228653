import logoDark from "@/images/logo-30.png";
import slideOneImage1 from "@/images/slider/slider-1.jpg";
import slideOneImage2 from "@/images/slider/slider-2.jpg";
import slideOneImage3 from "@/images/slider/slider-3.jpg";
import slideOneImage4 from "@/images/slider/slider-4.jpg";
import slideOneImage5 from "@/images/slider/slider-5.jpg";
import serviceImage1 from "@/images/services/service-1.jpg";
import serviceImage2 from "@/images/services/service-2.jpg";
import funfactShape from "@/images/shape/about-shape.png";
import galleryOne1 from "@/images/gallery/gallery-1.jpg";
import galleryOne2 from "@/images/gallery/gallery-2.jpg";
import galleryOne3 from "@/images/gallery/gallery-3.jpg";
import galleryOne4 from "@/images/gallery/gallery-4.jpg";
import galleryOne5 from "@/images/gallery/gallery-5.jpg";
import galleryOne6 from "@/images/gallery/gallery-6.jpg";
import testimonial1 from "@/images/testimonial/testimonial-1.jpg";
import testimonial2 from "@/images/testimonial/testimonial-2.jpg";
import testimonial3 from "@/images/testimonial/testimonial-3.jpg";
import blog1 from "@/images/blog/blog-1.jpg";
import blog2 from "@/images/blog/blog-2.jpg";
import blog3 from "@/images/blog/blog-3.jpg";
import blog4 from "@/images/blog/blog-4.jpg";
import blog5 from "@/images/blog/blog-5.jpg";
import blog6 from "@/images/blog/blog-6.jpg";
import team1 from "@/images/team/team-1.jpg";
import team2 from "@/images/team/team-2.jpg";
import team3 from "@/images/team/team-3.jpg";
import team4 from "@/images/team/team-4.jpg";
import comment1 from "@/images/comment-1.jpg";
import comment2 from "@/images/comment-2.jpg";

export const Logo = {
  dark: logoDark,
};

export const NavLinksData = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "About",
    url: "/about",
    subItems: [
      {
        name: "Firm History",
        url: "/firm-history",
      },
      {
        name: "Diversity",
        url: "/diversity",
      },
      {
        name: "Pro Bono",
        url: "/pro-bono",
      },
      {
        name: "Careers",
        url: "/careers",
      },
    ],
  },
  {
    name: "School Law",
    url: "/school-law",
  },
  {
    name: "Employment Law",
    url: "/employment-law",
  },
  {
    name: "Attorneys",
    url: "/attorneys",
  },
  {
    name: "News",
    url: "/articles",
    subItems: [
      {
        name: "Articles",
        url: "/articles",
      },
      {
        name: "Newsletters",
        url: "/newsletters",
      },
    ],
  },
  {
    name: "Events",
    url: "/events",
    // subItems: [
    //   {
    //     name: "Calendar View",
    //     url: "/calendar"
    //   },
    //   {
    //     name: "List View",
    //     url: "/calendar-list"
    //   },
    // ],
  },
  {
    name: "Contact",
    url: "/contact",
  },
];

export const TopbarInfos = [
  {
    icon: "icon-placeholder",
    text: "5010 Campuswood Dr, East Syracuse, NY 13057",
    url: "#",
  },
  {
    icon: "icon-phone-call",
    text: "315-437-7600",
    url: "tel:315-437-7600",
  },
];

export const SlideOneData = [
  {
    backgroundImage: slideOneImage1,
    title: "School Law",
    text: "ipsum dolor sit amet consectetur",
    button: "LEARN MORE",
    url: "/contact",
  },
  {
    backgroundImage: slideOneImage2,
    title: "Employment Law",
    text: "ipsum dolor sit amet consectetur",
    button: "LEARN MORE",
    url: "/contact",
  },
  {
    backgroundImage: slideOneImage3,
    title: "About us",
    text: "ipsum dolor sit amet consectetur",
    button: "LEARN MORE",
    url: "/contact",
  },
];

export const SlideTwoData = [
  {
    backgroundImage: slideOneImage4,
    title: "Heating and Air Conditioning Specialis",
    button: "LEARN MORE",
    url: "/contact",
  },
  {
    backgroundImage: slideOneImage5,
    title: "Keep your family happy this winter, and warm.",
    button: "LEARN MORE",
    url: "/contact",
  },
  {
    backgroundImage: slideOneImage4,
    title: "Heating and Air Conditioning Specialist",
    button: "LEARN MORE",
    url: "/contact",
  },
];

export const FeatureOneData = [
  {
    icon: "icon-building",
    title: "Education Law Expertise",
  },
  {
    icon: "icon-diploma",
    title: "Satisfaction Guarantee",
  },
  {
    icon: "icon-mechanic",
    title: "Employment Law Expertise",
  },
  {
    icon: "icon-handshake",
    title: "CNY Best Places to Work",
  },
  {
    icon: "icon-big_clock",
    title: "30 Years Experience",
  },
];

export const AboutOneData = {
  title: "Dolor sit amet consectetur adipiscing elit pellentesque habitant",
  tagLine: "Lorem ipsum",
  content:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. A erat nam at lectus urna.",
  button: {
    url: "/about",
    label: "LEARN MORE",
  },
};

export const ServiceOneData = {
  title: "Our practices",
  posts: [
    {
      image: serviceImage1,
      icon: "icon-building",
      title: "School Law",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. A erat nam at lectus urna..",
      button: {
        label: "LEARN MORE",
        url: "/service-details",
      },
    },
    {
      image: serviceImage2,
      icon: "icon-mechanic",
      title: "Employment Law",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. A erat nam at lectus urna..",
      button: {
        label: "LEARN MORE",
        url: "/service-details",
      },
    },
  ],
};

export const CallToActionOneData = {
  title: "Lorem ipsum dolor sit amet",
  button: {
    url: "/contact",
    label: "LEARN MORE",
  },
};

export const CallToActionTwoData = {
  left: {
    text: "Air Conditioning Services",
    title: "Cool it down",
  },
  right: {
    text: "Heating Services",
    title: "Heat it up",
  },
};

export const FunfactOneData = {
  shapeImage: funfactShape,
  title: "Numbers Speakers.",
  posts: [
    {
      count: 8870,
      text: "Air Conditioning Repairs and Install",
    },
    {
      count: 4760,
      text: "Air Conditioning Repairs and Install",
    },
    {
      count: 9032,
      text: "Air Conditioning Repairs and Install",
    },
  ],
};

export const GalleryOneData = [
  {
    title: "Maintenance and Repair",
    image: galleryOne1,
    url: "#",
  },
  {
    title: "Maintenance and Repair",
    image: galleryOne2,
    url: "#",
  },
  {
    title: "Maintenance and Repair",
    image: galleryOne3,
    url: "#",
  },
  {
    title: "Maintenance and Repair",
    image: galleryOne4,
    url: "#",
  },
  {
    title: "Maintenance and Repair",
    image: galleryOne5,
    url: "#",
  },
  {
    title: "Maintenance and Repair",
    image: galleryOne6,
    url: "#",
  },
];

export const TestimonialOneData = {
  title: "Testimonials",
  posts: [
    {
      image: testimonial1,
      content:
        "This is due to their excellent service, competitive pricing and customer support . It's throughly refresing to get such a personal touch.",
      name: "Della Barnett",
      designation: "CEO",
    },
    {
      image: testimonial2,
      content:
        "This is due to their excellent service, competitive pricing and customer support . It's throughly refresing to get such a personal touch.",
      name: "Max Dunn",
      designation: "Developer",
    },
    {
      image: testimonial3,
      content:
        "This is due to their excellent service, competitive pricing and customer support . It's throughly refresing to get such a personal touch.",
      name: "Alberta Potter",
      designation: "Director",
    },
    {
      image: testimonial1,
      content:
        "This is due to their excellent service, competitive pricing and customer support . It's throughly refresing to get such a personal touch.",
      name: "Della Barnett",
      designation: "CEO",
    },
    {
      image: testimonial2,
      content:
        "This is due to their excellent service, competitive pricing and customer support . It's throughly refresing to get such a personal touch.",
      name: "Max Dunn",
      designation: "Developer",
    },
    {
      image: testimonial3,
      content:
        "This is due to their excellent service, competitive pricing and customer support . It's throughly refresing to get such a personal touch.",
      name: "Alberta Potter",
      designation: "Director",
    },
  ],
};

export const FooterBottomData = {
  social: [
    {
      icon: "fab fa-twitter",
      url: "https://twitter.com/ferrarafiorenza",
    },
    {
      icon: "fab fa-linkedin",
      url: "https://www.linkedin.com/company/ferrara-fiorenza-pc",
    },
    {
      icon: "fab fa-facebook",
      url: "https://www.facebook.com/FerraraFiorenza/",
    },
  ],
};

export const FooterInfo = [
  {
    icon: "icon-placeholder",
    tagLine: "Address",
    text: "5010 Campuswood Dr, East Syracuse, NY 13057",
    url: "#",
  },
];

export const FooterWidgets = {
  about: {
    title: "About Ferrara Fiorenza PC",
    text: "Ferrara Fiorenza PC offers a blend of expertise and thoughtful representation in analyzing and solving the challenges faced by school districts, BOCES and all types of employers.",
    tagLine: "Contact us",
    phone: "315-437-7600",
  },
  linkOne: {
    title: "Quick Links",
    menu: [
      {
        lable: "School Law",
        url: "/school-law",
      },
      {
        lable: "Employment Law",
        url: "/employment-law",
      },
      {
        lable: "About Us",
        url: "/about",
      },
      {
        lable: "Attorneys",
        url: "/attorneys",
      },
    ],
  },
  linkTwo: {
    menu: [],
  },
};

export const BlogOneData = {
  title: "Our latest news",
  posts: [
    {
      image: blog1,
      date: "07 Nov",
      author: "Mike Dodd",
      commentCount: 3,
      title: "Post-Pandemic Construction Projects?",
      url: "/blog-details",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. A erat nam at lectus urna..",
    },
    {
      image: blog2,
      date: "07 Nov",
      author: "Mike Dodd",
      commentCount: 3,
      title:
        "CSE Must Determine COVID-19 Related Compensatory Services In First Instance",
      url: "/blog-details",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. A erat nam at lectus urna..",
    },
    {
      image: blog3,
      date: "07 Nov",
      author: "Mike Dodd",
      commentCount: 3,
      title: "When Should We Manifest a Manifestation Determination?",
      url: "/blog-details",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. A erat nam at lectus urna..",
    },
  ],
};

export const BlogTwoData = {
  posts: [
    {
      image: blog1,
      date: "07 Nov",
      author: "Mike Dodd",
      commentCount: 3,
      title: "When Should We Manifest a Manifestation Determination?",
      url: "/blog-details",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. A erat nam at lectus urna..",
    },
    {
      image: blog2,
      date: "07 Nov",
      author: "Mike Dodd",
      title:
        "CSE Must Determine COVID-19 Related Compensatory Services In First Instance",
      url: "/blog-details",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. A erat nam at lectus urna..",
    },
    {
      image: blog3,
      date: "07 Nov",
      author: "Mike Dodd",
      commentCount: 3,
      title: "Post-Pandemic Construction Projects?",
      url: "/blog-details",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. A erat nam at lectus urna..",
    },
    {
      image: blog4,
      date: "07 Nov",
      author: "Mike Dodd",
      commentCount: 3,
      title:
        "Be Prepared for New Law Requiring Notice of Electronic Monitoring",
      url: "/blog-details",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. A erat nam at lectus urna..",
    },
    {
      image: blog5,
      date: "07 Nov",
      author: "Mike Dodd",
      commentCount: 3,
      title:
        "New “Test-to-Stay” Guidelines Require Written Board of Education Policy",
      url: "/blog-details",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. A erat nam at lectus urna..",
    },
    {
      image: blog6,
      date: "07 Nov",
      author: "Mike Dodd",
      commentCount: 3,
      title: "N.Y. Open Meetings Law Imposes New Posting Requirements",
      url: "/blog-details",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. A erat nam at lectus urna..",
    },
  ],
};

export const VideoOneData = {
  title: "See the Ferrara Team in action",
  id: "oV8vtpepyGY",
};

export const AboutTwoData = {
  caption: " 30 <span>years experience</span> ",
  blockTitle: {
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ",
    tagline: "Ferrara Fiorenza PC",
  },
  lists: [
    {
      text: " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ",
    },
    {
      text: " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ",
    },
    {
      text: " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ",
    },
    {
      text: " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ",
    },
    {
      text: " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ",
    },
    {
      text: " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ",
    },
  ],
  offer: {
    tagline: "10% off",
    text: "on our all services for senior citizens.",
  },
};

export const TeamOneData = {
  title: "Meet our Attorneys",
  posts: [
    {
      image: team1,
      name: "Mike Dodd",
      designation: "Partner",
      attorneyUrl: "/attorney",
      social: [
        {
          icon: "fa-twitter",
          url: "https://twitter.com/ferrarafiorenza",
        },
        {
          icon: "fa fa-facebook",
          url: "https://www.facebook.com/FerraraFiorenza/",
        },
      ],
    },
    {
      image: team2,
      name: "Mike Dodd",
      designation: "Partner",
      attorneyUrl: "/attorney",
      social: [
        {
          icon: "fa-twitter",
          url: "https://twitter.com/ferrarafiorenza",
        },
        {
          icon: "fa fa-facebook",
          url: "https://www.facebook.com/FerraraFiorenza/",
        },
      ],
    },
    {
      image: team3,
      name: "Mike Dodd",
      designation: "Partner",
      attorneyUrl: "/attorney",
      social: [
        {
          icon: "fa-twitter",
          url: "https://twitter.com/ferrarafiorenza",
        },
        {
          icon: "fa fa-facebook",
          url: "https://www.facebook.com/FerraraFiorenza/",
        },
      ],
    },
    {
      image: team4,
      name: "Mike Dodd",
      designation: "Partner",
      attorneyUrl: "/attorney",
      social: [
        {
          icon: "fa-twitter",
          url: "https://twitter.com/ferrarafiorenza",
        },
        {
          icon: "fa fa-facebook",
          url: "https://www.facebook.com/FerraraFiorenza/",
        },
      ],
    },
    {
      image: team4,
      name: "Mike Dodd",
      designation: "Partner",
      attorneyUrl: "/attorney",
      social: [
        {
          icon: "fa-twitter",
          url: "https://twitter.com/ferrarafiorenza",
        },
        {
          icon: "fa fa-facebook",
          url: "https://www.facebook.com/FerraraFiorenza/",
        },
      ],
    },
    {
      image: team4,
      name: "Mike Dodd",
      designation: "Partner",
      attorneyUrl: "/attorney",
      social: [
        {
          icon: "fa-twitter",
          url: "https://twitter.com/ferrarafiorenza",
        },
        {
          icon: "fa fa-facebook",
          url: "https://www.facebook.com/FerraraFiorenza/",
        },
      ],
    },
    {
      image: team4,
      name: "Mike Dodd",
      designation: "Partner",
      attorneyUrl: "/attorney",
      social: [
        {
          icon: "fa-twitter",
          url: "https://twitter.com/ferrarafiorenza",
        },
        {
          icon: "fa fa-facebook",
          url: "https://www.facebook.com/FerraraFiorenza/",
        },
      ],
    },
    {
      image: team4,
      name: "Mike Dodd",
      designation: "Partner",
      attorneyUrl: "/attorney",
      social: [
        {
          icon: "fa-twitter",
          url: "https://twitter.com/ferrarafiorenza",
        },
        {
          icon: "fa fa-facebook",
          url: "https://www.facebook.com/FerraraFiorenza/",
        },
      ],
    },
    {
      image: team4,
      name: "Mike Dodd",
      designation: "Partner",
      attorneyUrl: "/attorney",
      social: [
        {
          icon: "fa-twitter",
          url: "https://twitter.com/ferrarafiorenza",
        },
        {
          icon: "fa fa-facebook",
          url: "https://www.facebook.com/FerraraFiorenza/",
        },
      ],
    },
    {
      image: team4,
      name: "Mike Dodd",
      designation: "Partner",
      attorneyUrl: "/attorney",
      social: [
        {
          icon: "fa-twitter",
          url: "https://twitter.com/ferrarafiorenza",
        },
        {
          icon: "fa fa-facebook",
          url: "https://www.facebook.com/FerraraFiorenza/",
        },
      ],
    },
    {
      image: team4,
      name: "Mike Dodd",
      designation: "Partner",
      attorneyUrl: "/attorney",
      social: [
        {
          icon: "fa-twitter",
          url: "https://twitter.com/ferrarafiorenza",
        },
        {
          icon: "fa fa-facebook",
          url: "https://www.facebook.com/FerraraFiorenza/",
        },
      ],
    },
    {
      image: team4,
      name: "Mike Dodd",
      designation: "Partner",
      attorneyUrl: "/attorney",
      social: [
        {
          icon: "fa-twitter",
          url: "https://twitter.com/ferrarafiorenza",
        },
        {
          icon: "fa fa-facebook",
          url: "https://www.facebook.com/FerraraFiorenza/",
        },
      ],
    },
    {
      image: team4,
      name: "Mike Dodd",
      designation: "Partner",
      attorneyUrl: "/attorney",
      social: [
        {
          icon: "fa-twitter",
          url: "https://twitter.com/ferrarafiorenza",
        },
        {
          icon: "fa fa-facebook",
          url: "https://www.facebook.com/FerraraFiorenza/",
        },
      ],
    },
    {
      image: team4,
      name: "Mike Dodd",
      designation: "Partner",
      attorneyUrl: "/attorney",
      social: [
        {
          icon: "fa-twitter",
          url: "https://twitter.com/ferrarafiorenza",
        },
        {
          icon: "fa fa-facebook",
          url: "https://www.facebook.com/FerraraFiorenza/",
        },
      ],
    },
  ],
};

export const AboutThreeData = {
  blockTitle: {
    tagline: "Our success story",
    title: "Experience quality air conditioning service in broklyn new york",
  },
  summery: "Lorem ipsum dolors sit amet elit magnis amet",
  button: {
    lable: "LEARN MORE",
    url: "/about",
  },
  box: [
    {
      icon: "icon-maintenance",
      title: "We make as cool again",
    },
    {
      icon: "icon-big-ac",
      title: "Keep your business cool",
    },
  ],
};

export const AboutFourData = {
  blockTitle: {
    title: "We are trusted by more than <span>2000</span> clients",
    text1:
      "Tincidunt elit magnis nulla facilisis sagittis is maecenas. Sapien nunced amet ultrices, dolores sit ipsum velit purus aliquet, massa fringilla leo orci.",
    text2:
      "Lorem ipsum dolors sit amet elit magnis amet ultrices purusrfed aliquet. Lorem ipsum dolors sit amet elit magnis amet.",
  },
};

export const BenefitOneData = {
  title: "Our benefits",
  posts: [
    {
      icon: "icon-big_clock",
      title: "Emergency Service",
      text: "There are many passages of lorem ipsum available but the majority have alteration.",
    },
    {
      icon: "icon-wallet",
      title: "No Upfront Payment",
      text: "There are many passages of lorem ipsum available but the majority have alteration.",
    },
    {
      icon: "icon-diploma",
      title: "Satisfaction Guarantee",
      text: "There are many passages of lorem ipsum available but the majority have alteration.",
    },
    {
      icon: "icon-mechanic",
      title: "8 Years Experience",
      text: "There are many passages of lorem ipsum available but the majority have alteration.",
    },
  ],
};

export const ServiceSidebarListData = [
  {
    icon: "icon-building",
    title: "School \r Law",
    list: [
      {
        label: "Air Conditioner Maintenance",
        url: "#",
      },
      {
        label: "Air Conditioner Repair",
        url: "#",
      },
      {
        label: "Air Conditioner Replacement",
        url: "#",
      },
      {
        label: "Ductless Split AC Systems",
        url: "#",
      },
      {
        label: "Duct Cleaning & Installation",
        url: "#",
      },
    ],
  },
  {
    icon: "icon-mechanic",
    title: "Employment Law",
    list: [
      {
        label: "Air Conditioner Maintenance",
        url: "#",
      },
      {
        label: "Air Conditioner Repair",
        url: "#",
      },
      {
        label: "Air Conditioner Replacement",
        url: "#",
      },
      {
        label: "Ductless Split AC Systems",
        url: "#",
      },
      {
        label: "Duct Cleaning & Installation",
        url: "#",
      },
    ],
  },
];

export const ServiceSidebarOtherData = [
  {
    icon: "icon-ac",
    title: "Employment Law",
    content: {
      icon: "icon-phone-call",
      title: "<span>Work with us </span>315-437-7600",
    },
  },
  {
    icon: "icon-building",
    title: "School Law",
    content: {
      icon: "icon-phone-call",
      title: "<span>Work with us </span>315-437-7600",
    },
  },
];

export const CommentPostData = [
  {
    name: "David Martin",
    image: comment1,
    time: "10 Nov, 2018 - 4:00 pm",
    text: "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et aliquet.",
  },
  {
    name: "Jessica Brown",
    image: comment2,
    time: "10 Nov, 2018 - 4:00 pm",
    text: "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et aliquet.",
  },
];
